<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-b">用户充电功率导出</span>
        </div>
        <el-card class="el-main">
            <div class="a-fs-16 a-fw-700">
                <span>过去30天有过充电记录</span>
            </div>
            <el-form :model="form" ref="form" :rules='rules' label-width="145px" label-position="left" class="a-mt-20">
                <el-form-item label="车辆类型" prop="type" >
                    <el-select v-model="form.type" placeholder="请选择车辆类型" style="width: 400px" @change='bikeTypeChange'>
                        <el-option v-for="(item,index) in typeDic"
                            :key="index"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="当前套餐档位" prop="bikeBatteryId" >
                    <el-select v-model="form.bikeBatteryId" placeholder="请选择电瓶规格" style="width: 400px" >
                        <el-option v-for="(item,index) in batteryVoltageDic"
                            :key="index"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="近30天有功功率" prop="power">
                    <!-- 1是>= ，2是< -->
                    <jd-input-condition
                        class="powerInput"
                        style="padding: 0 !important;width: 400px" 
                        :options='powerCondition'
                        :value.sync="form.power" 
                        :condition.sync="operate" 
                        :showAppend='true'
                        inputType="money">
                        <template>
                            <span>W</span>
                        </template>
                    </jd-input-condition>
                    
                </el-form-item>
            </el-form>
            <div class="a-line-t-e0 footerBox">
                <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">导出</el-button>
                <el-button type="primary" plain class="a-ml-24 a-mt-15" @click="reset">重置</el-button>
                <!-- <el-button class="a-ml-24 a-mt-15" @click="cancelSubmit">返回</el-button> -->
            </div>
        </el-card>
    </div>
</template>

<script>
import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex';
import util from '../../utils/util.js'
export default {
    data() {
        var checkPower = (rule, value, callback) => {
            if(!this.form.power){
                callback(new Error('请输入功率'))
            } else if (Number(this.form.power) <= 0) {
                callback(new Error('功率必须大于0'))
            } else{
                callback()
            }
        };
        return {
            util: util,
            form: {
                type: '',
                bikeBatteryId: '',
                power: '',
            },
            rules: {
                type: [{required: true, message:'请选择车辆类型', trigger: 'change'}],
                bikeBatteryId: [{required: true, message:'请选择套餐档位', trigger: 'change'}],
                power: [{ validator: checkPower, trigger: 'blur' }],
            },
            powerCondition: [{
                label: '大于',
                value: 1
            }],
            operate: 1,
            typeDic: [],
            batteryVoltageDic: []
        }
    },
    created () {
        this.$getDic('userBikeType','select').then(res=>{ this.typeDic = res; })
        // this.$getDic('userBikeBattery','select').then(res=>{ this.batteryVoltageDic = res; })
    },
    mounted() { },
    methods: {
        bikeTypeChange () {
            this.form.bikeBatteryId = ''
            this.getBikeBatteryDic()
        },
        getBikeBatteryDic () {
            this.$Axios._post({
                url: this.$Config.apiUrl.batterybList,
                showLoading: false,
                params: {
                    bikeId: this.form.type
                }
            }).then((res) => {
                if (res.result.code == 0) {
                    this.batteryVoltageDic = res.result.data.map(item=>{
                        return {
                            ...item,
                            label: item.batteryVoltage,
                            value: JSON.stringify(item.id)
                        }
                    })
                }
            })
        },
        reset () {
            this.form = {
                type: '',
                bikeBatteryId: '',
                power: '',
            }
            this.operate = 1
            this.$refs['form'].clearValidate()
        },
        submit () {
            // let startDate = this.pageParam.params.startDate || this.$getDay.getTodayBeforeDays(92) + ' 00:00:00'
            // let endDate = this.pageParam.params.endDate || this.$getDay.getTodayBeforeDays(0) + ' 00:00:00'
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.$exfile({
                        code: 25,
                        fileName: '近30天充电功率用户导出',
                        startTime: '',
                        endTime: '',
                        params: {
                            ...this.form
                        }
                    })
                }
            })
            
        }
    },
}
</script>

<style scoped lang="scss">
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
    .powerInput{
        /deep/ &>div{
            margin: 0 !important;
        }
    }
</style>
